@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: $dark-header-background;
  border-bottom: 1px solid $divider;
  padding: 12px 5%;

  .icon {
    color: $fb-blue;

    font-size: 22px;
    margin: 0 12px 0 0;

    @include sm {
      font-size: 24px;
      margin: 0 18px 0 0;
    }

    @include lg {
      font-size: 28px;
      margin: 0 24px 0 0;
    }
  }

  .avatar {
    vertical-align: middle;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    margin: 0 12px;
    color: $light-font;

    .title {
      margin: 0 0 2px 0;
      font-size: 16px;

      @include sm {
        font-size: 18px;
      }

      @include lg {
        font-size: 24px;
      }
    }

    .description {
      font-weight: normal;
      margin: 2px 0 0 0;
      font-size: 8px;

      @include sm {
        font-size: 10px;
      }

      @include lg {
        font-size: 12px;
      }
    }
  }

  .actions {
    margin-left: auto;

    .icon {
      cursor: pointer;
      color: $fb-blue;
      font-size: 18px;
      margin: 0 12px;

      @include sm {
        font-size: 20px;
        margin: 0 16px;
      }

      @include lg {
        font-size: 24px;
        margin: 0 20px;
      }
    }
  }
}
