@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.image-message-group {
  display: flex;
  flex-wrap: wrap;
  margin: 3px 12px 0 20px;

  .image-message {
    border-radius: 4px;
    height: 100%;
    margin: 2px;
    width: 200px;
    cursor: pointer;

    @include lg {
      width: 330px;
    }
  }
}

.lightbox-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: $dark-header-background;
  height: 72px;
  border-top: 1px solid $divider;

  .icon {
    color: $fb-blue;
    font-size: 24px;
  }
}
