@import "../../styles/colors.scss";
@import "../../styles//mixins.scss";

.message {
  display: flex;
  align-items: flex-end;

  .chat-bubble {
    display: flex;
    align-items: flex-start;
    width: fit-content;
    color: $light-font;
    background-color: $received-message-background;
    border-radius: 1.3em;
    padding: 8px 15px;
    margin: 3px 0 0 3px;
    font-size: 16px;

    @include sm {
      font-size: 20px;
    }

    @include lg {
      font-size: 24px;
    }

    &.sent {
      background-color: $fb-blue;
      color: $light-font;
      border: 2px solid $fb-blue;
      margin: 12px 0 12px auto;
    }

    &.action-btn {
      background-color: $dark-background;
      color: $fb-blue;
      border: 2px solid $fb-blue;
      justify-content: flex-end;
      margin-top: 12px;
      margin-bottom: 12px;

      &:hover {
        background-color: $fb-blue;
        color: white;
        cursor: pointer;
      }
    }

    &.received {
      background-color: $received-message-background;

      &.no-avatar {
        margin-left: 20px;
      }
    }
  }
}
