@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.message-list {
  display: flex;
  flex-direction: column;
  margin: 0 5%;

  .message-heading {
    display: flex;
    justify-content: center;

    .message-time {
      color: $timestamp-font;
      font-size: 10px;
      font-weight: 500;

      @include lg {
        font-size: 12px;
      }
    }
  }
}

.reply-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}
