// @import "~antd/dist/antd.css";
@import "~antd/dist/antd.dark.css";
// @import "~antd/dist/antd.compact.css";

@import "./styles/colors.scss";

body {
  background-color: $dark-background;
  line-height: 1.34;
}

.root {
  height: 100%;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  overflow: auto;
  padding-top: 12px;
  padding-bottom: 96px;
}
